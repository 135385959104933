import React from 'react';
import { Avatar, Box, Button, Grid, ListItem, ListItemIcon, ListItemText, ListItemAvatar } from "@mui/material";
import Link from '@mui/material/Link';
import { H1, H6, Small, Span, Tiny } from "./Typography";

const ChaithanyaKuteeraPrakashaha = () => {
    return (
        <React.Fragment>
            <Box pt={6}>

            </Box>
            <Box margin="auto" textAlign="center" sx={{ bgcolor: '#faf8e3', borderRadius:5 }} p={2} maxWidth={900}>
            <Tiny color="text.secondary" fontSize={14} py={2} fontWeight={400}>
                <Span fontWeight={900} color="#a84232">Chaithanya Kuteera Prakashaha</Span> was formed by our Guruji to foster Bhakti in the hearts of people by getting to know about various 
                Bhaktas, Saints, Alwars, Nayanars, etc. from Bharatha Khande spanning across several eons. Click the below link to navigate to Chaithanya Kuteera Prakashaha.

        
                </Tiny>
                <Link href="https://chaithanya-kuteera-prakashaha.org/">Chaithanya Kuteera Prakashaha</Link>
            </Box>
        </React.Fragment>
    )
}

export default ChaithanyaKuteeraPrakashaha;