import React from 'react';
import Box from '@mui/material/Box';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link
  } from "react-router-dom";
import About from './About';
import Objectives from './Objectives';
import Contact from './Contact';
import ChaithanyaKuteeraPrakashaha from './ChaithanyaKuteeraPrakashaha';
//import Events from './Events';
import EventsTable from './EventsTable';


const Body = () => {
    return (
        <Box sx={{backgroundImage: `url("karangal.png")`, minHeight:600, backgroundSize:'contain'}}>
            
                <div>
                    {false && <React.Fragment>
                        <Link to="/objectives">Objectives</Link>
                        <Link to="/chaithanya-kuteera-prakashaha">Chaithanya Kuteera Prakashaha</Link>
                        <Link to="/contact">Contact</Link>
                    </React.Fragment>}
                
                    {/* A <Switch> looks through its children <Route>s and
                        renders the first one that matches the current URL. */}
                    <Routes>
                        <Route path='/objectives' element={<Objectives/>} />
                        <Route path='/contact' element={<Contact/>} />
                        <Route path='/chaithanya-kuteera-prakashaha' element={<ChaithanyaKuteeraPrakashaha/>} />
                        <Route path='/events' element={<EventsTable/>} />
                        <Route path='/' element={<About/>} />
                    
                    </Routes>
                </div>
            
            
        </Box>
    )
}

export default Body;