import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import logo from './logo.svg';
import './App.css';
import Header from './components/Header';
import Body from './components/Body';
import Footer from './components/Footer';

function App() {
  return (
    <Router>
      <div className="App">
      <Header></Header>
      <Body></Body>
      <Footer></Footer>
    </div>
    </Router>
    
  );
}

export default App;
